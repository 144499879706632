// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-project-babbelbord-js": () => import("./../../../src/pages/project/babbelbord.js" /* webpackChunkName: "component---src-pages-project-babbelbord-js" */),
  "component---src-pages-project-beathoven-js": () => import("./../../../src/pages/project/beathoven.js" /* webpackChunkName: "component---src-pages-project-beathoven-js" */),
  "component---src-pages-project-custodian-js": () => import("./../../../src/pages/project/custodian.js" /* webpackChunkName: "component---src-pages-project-custodian-js" */),
  "component---src-pages-project-jammin-js": () => import("./../../../src/pages/project/jammin.js" /* webpackChunkName: "component---src-pages-project-jammin-js" */),
  "component---src-pages-project-master-thesis-js": () => import("./../../../src/pages/project/master-thesis.js" /* webpackChunkName: "component---src-pages-project-master-thesis-js" */),
  "component---src-pages-project-whistleblower-js": () => import("./../../../src/pages/project/whistleblower.js" /* webpackChunkName: "component---src-pages-project-whistleblower-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

